<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Datatable from "@/router/layouts/table-datatable.vue";
// import Pagination from "@/router/layouts/table-pagination.vue";
// import axios from "axios";
import Swal from "sweetalert2";
// import moment from "moment";

export default {
    page: {
        title: "Daftar Asesmen",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        // Datatable,
        // Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "id_menu_master" },
            { width: "auto", label: "Nama Menu", name: "menu_master_nama" },
            { width: "100px", label: "Type", name: "menu_master_type" },
            { width: "125px", label: "Icon Menu", name: "menu_master_icon" },
            { width: "auto", label: "Link Path", name: "menu_master_link_path" },
            { width: "auto", label: "Link Name", name: "menu_master_link_name" },
            { width: "80px", label: "Urutan", name: "menu_master_urutan" },
            { width: "75px", label: "Parent", name: "menu_master_parent" },
            { width: "150px", label: "Created At", name: "created_at" },
            { width: "150px", label: "Updated At", name: "updated_at" },
            { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
            { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Daftar Asesmen",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Daftar Asesmen",
                    href: "/asesmen-asesor",
                },
                {
                    text: "Detail Asesmen",
                    active: true
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: true,
            table_data: [],
            columns: columns,
            sortKey: "id_menu_master", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: "ENABLE", // untuk filtering data
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            
            meta_dokumen: [
                {
                    'nama_dokumen': 'Ceklis Verifikasi TUK',
                    'link': ''
                },
                {
                    'nama_dokumen': 'FR.APL-01 Permohonan Sertifikasi Kompetensi + Bukti Persyaratan Dasar',
                    'link': 'dokumen/files/FR APL 01'
                },
            ],
        };
    },
    mounted() {
    },
    methods: {
        ubahStatus(id) {
            console.log(id);
            var alert_text = "Apakah Anda yakin untuk mengubah status?";
            Swal.fire({
                title: "Peringatan !",
                text: alert_text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya",
                cancelButtonText: "Tidak",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.close();
                } else {
                    Swal.close();
                }
            });
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                    <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Nomor Registrasi</label>
                                    <div class="col">
                                        <input id="" class="form-control" type="text" for="text" value="REG/0001/LSP/VIII/2023">
                                    </div>
                                </div>
                                <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                    <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Nomor Jadwal Sertifikasi</label>
                                    <div class="col">
                                        <input id="" class="form-control" type="text" for="text" value="0060/LSP/MUK/II/2023">
                                    </div>
                                </div>
                                <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                    <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Skema Sertifikasi</label>
                                    <div class="col">
                                        <input id="" class="form-control" type="text" for="text" value="Staf Muda Pengujian Neurovirulence dan Extraneous Agen">
                                    </div>
                                </div>
                                <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                    <label class="col-lg-4 col-form-label col-form-label" id="" for="text">TUK</label>
                                    <div class="col">
                                        <input id="" class="form-control" type="text" for="text" value="Ruang Meeting LSP">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                
                                
                                <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                    <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Nama Asesi</label>
                                    <div class="col">
                                        <input id="" class="form-control" type="text" for="text" value="Rizky Ramadhan">
                                    </div>
                                </div>
                                
                                <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                    <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Tanggal Pelaksanaan</label>
                                    <div class="col">
                                        <input id="" class="form-control" type="text" for="text" value="12/12/2021">
                                    </div>
                                </div>
                                
                            </div>
                            <div class="form-group col-md-12 mt-4">
                                <label for="tipe_menu">Daftar Dokumen </label>
                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                    <thead class="bg-dark text-center text-white">
                                        <tr>
                                            <th>No</th>
                                            <th>Nama Dokumen</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="meta_dokumen.length == 0">
                                            <td colspan="3">TIdak Ada Data</td>
                                        </tr>
                                        <tr v-for="(item, index) in meta_dokumen" :key="index">
                                            <td>
                                                {{index + 1}}
                                            </td>
                                            <td>
                                                {{item.nama_dokumen }}
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-primary"><i class="fas fa-search"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <b-button type="button" class="mx-4 btn-sm" variant="info">
                                            Submit</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <!-- <h4 class="card-title mb-5">Timeline</h4> -->
                            <div class="hori-timeline" dir="ltr">
                                <div class="scroll-timeline">
                                    <ul class="list-inline events">
                                        <li class="list-inline-item event-list">
                                            <div class="px-4">
                                                <div class="event-date bg-soft-primary text-primary">30 Juli 2023</div>
                                                <h6 class="font-size-14">Proses Asesmen</h6>
                                                <p class="text-muted">Asesor</p>
                                                <div></div>
                                            </div>
                                        </li>
                                        <li class="list-inline-item event-list">
                                            <div class="px-4">
                                                <div class="event-date bg-soft-success text-success">31 Juli 2023</div>
                                                <h6 class="font-size-14">Selesai</h6>
                                                <p class="text-muted">Asesor</p>
                                                <div></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card -->
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped>
.col-md-custom {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
}

.list-inline{
    margin-top: 10px;
}

.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: rgba(64, 144, 203, .3) !important;
}

.bg-soft-success {
    background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
    background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
    background-color: rgba(249, 213, 112, .3) !important;
}

.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}
.scroll-timeline{
    overflow: auto;
    white-space: nowrap;
}
</style>
